/*! Copyright [Amazon.com](http://amazon.com/), Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0 */
import * as React from "react";
import Routes from "../Routes";

/**
 * Defines the App layout and contains logic for routing.
 */
const App: React.FC = () => {
  return <Routes />;
};

export default App;
