import { Alert } from "@cloudscape-design/components";
import { FC } from "react";
import { ApiError, errorTitle, isApiError } from "../hooks/useDefaultApiClient";

export interface ErrorAlertProps {
  readonly errors: unknown[];
}

export const ErrorAlert: FC<ErrorAlertProps> = ({ errors }) => {
  const apiErrors = errors.filter((error) => isApiError(error)) as ApiError[];

  return (
    <>
      <Alert
        type="error"
        header={
          apiErrors.length > 0 ? errorTitle(apiErrors[0].status) : "Error"
        }
      >
        {apiErrors.length > 0
          ? apiErrors[0].details.message
          : `An unexpected error occurred.`}
      </Alert>
    </>
  );
};
