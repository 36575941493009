// Import models
import type {
  BadRequestErrorResponseContent,
  GetDemonstratorResponseContent,
  InternalFailureErrorResponseContent,
  ListDemonstratorsResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
  
} from '../models';

// Import request parameter interfaces
import {
    GetDemonstratorRequest,
    ListDemonstratorsRequest,
    
    
} from '..';

import { ResponseError } from '../runtime';
import { DefaultApi } from './DefaultApi';
import { DefaultApiDefaultContext } from "./DefaultApiClientProvider";

import {
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { createContext, useContext } from "react";

/**
 * Context for the API client used by the hooks.
 */
export const DefaultApiClientContext = createContext<DefaultApi | undefined>(undefined);

const NO_API_ERROR = new Error(`DefaultApi client missing. Please ensure you have instantiated the DefaultApiClientProvider with a client instance.`);


/**
 * useQuery hook for the GetDemonstrator operation
 */
export const useGetDemonstrator = <TError = ResponseError>(
    params: GetDemonstratorRequest,
    options?: Omit<UseQueryOptions<GetDemonstratorResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetDemonstratorResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getDemonstrator", params], () => api.getDemonstrator(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the ListDemonstrators operation
 */
export const useListDemonstrators = <TError = ResponseError>(
    params: ListDemonstratorsRequest,
    options?: Omit<UseInfiniteQueryOptions<ListDemonstratorsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<ListDemonstratorsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["listDemonstrators", params], ({ pageParam }) => api.listDemonstrators({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

