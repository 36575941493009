/*! Copyright [Amazon.com](http://amazon.com/), Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0 */
import { DefaultApiClientProvider as DefaultApiClientProvider_ } from "@ods/api-typescript-react-query-hooks";
import React from "react";
import { useDefaultApiClient } from "../../hooks/useDefaultApiClient";

/**
 * Sets up the runtimeContext and Cognito auth.
 *
 * This assumes a runtime-config.json file is present at '/'. In order for Auth to be set up automatically,
 * the runtime-config.json must have the following properties configured: [region, userPoolId, userPoolWebClientId, identityPoolId].
 */
const DefaultApiClientProvider: React.FC<any> = ({ children }) => {
  const client = useDefaultApiClient();

  return client ? (
    <DefaultApiClientProvider_ apiClient={client}>
      {children}
    </DefaultApiClientProvider_>
  ) : (
    <></>
  );
};

export default DefaultApiClientProvider;
