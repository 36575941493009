import { Box } from "@cloudscape-design/components";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface StudioBackCardProps {}

export const StudioBackCard: React.FC<StudioBackCardProps> = ({}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/", { replace: false })}
      style={{ height: "100%" }}
    >
      <Box>
        <img src={"/images/left-arrow.png"} style={{ scale: "80%" }} />
      </Box>
    </div>
  );
};
