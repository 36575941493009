import { ColumnLayout, Spinner } from "@cloudscape-design/components";
import { useListDemonstrators } from "@ods/api-typescript-react-query-hooks";
import { FC } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../components/error-alert";
import { StudioCard } from "../components/studio-card";

export interface DemonstratorsProps {}

export const Demonstrators: FC<DemonstratorsProps> = () => {
  const demonstrators = useListDemonstrators({});
  const navigate = useNavigate();

  if (demonstrators.isError) {
    return <ErrorAlert errors={[demonstrators.error]} />;
  }

  return (
    <>
      {!demonstrators.isLoading && demonstrators.data ? (
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          initialLoad={true}
          threshold={100}
          loadMore={() => {
            if (!demonstrators.isFetchingNextPage) {
              void demonstrators.fetchNextPage();
            }
          }}
          hasMore={demonstrators.hasNextPage}
          loader={<Spinner />}
        >
          <div style={{ margin: 100 }}>
            <ColumnLayout columns={4}>
              {demonstrators.data.pages
                .flatMap((p) => p.demonstrators)
                .sort((a, b) =>
                  a.name.toUpperCase() >= b.name.toUpperCase() ? 1 : -1,
                )
                .map(
                  (d) =>
                    d.demonstratorId !== "Example" && (
                      <StudioCard
                        title={d.name}
                        imageUrl={`/images/${d.demonstratorId}.jpg`}
                        onClick={() => navigate(`/${d.demonstratorId}`)}
                      />
                    ),
                )}
            </ColumnLayout>
          </div>
        </InfiniteScroll>
      ) : (
        <Spinner size="large" />
      )}
    </>
  );
};
