/* tslint:disable */
/* eslint-disable */
/**
 * OdsService
 * ODS Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestErrorResponseContent,
  GetDemonstratorResponseContent,
  InternalFailureErrorResponseContent,
  ListDemonstratorsResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
} from '../models';
import {
    BadRequestErrorResponseContentFromJSON,
    BadRequestErrorResponseContentToJSON,
    GetDemonstratorResponseContentFromJSON,
    GetDemonstratorResponseContentToJSON,
    InternalFailureErrorResponseContentFromJSON,
    InternalFailureErrorResponseContentToJSON,
    ListDemonstratorsResponseContentFromJSON,
    ListDemonstratorsResponseContentToJSON,
    NotAuthorizedErrorResponseContentFromJSON,
    NotAuthorizedErrorResponseContentToJSON,
    NotFoundErrorResponseContentFromJSON,
    NotFoundErrorResponseContentToJSON,
} from '../models';

export interface GetDemonstratorRequest {
    demonstratorId: string;
}

export interface ListDemonstratorsRequest {
    nextToken?: string;
    pageSize?: number;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async getDemonstratorRaw(requestParameters: GetDemonstratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDemonstratorResponseContent>> {
        if (requestParameters.demonstratorId === null || requestParameters.demonstratorId === undefined) {
            throw new runtime.RequiredError('demonstratorId','Required parameter requestParameters.demonstratorId was null or undefined when calling getDemonstrator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/demonstrators/{demonstratorId}`.replace(`{${"demonstratorId"}}`, encodeURIComponent(String(requestParameters.demonstratorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDemonstratorResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async getDemonstrator(requestParameters: GetDemonstratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDemonstratorResponseContent> {
        const response = await this.getDemonstratorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listDemonstratorsRaw(requestParameters: ListDemonstratorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDemonstratorsResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/demonstrators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDemonstratorsResponseContentFromJSON(jsonValue));
    }

    /**
     */
    async listDemonstrators(requestParameters: ListDemonstratorsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDemonstratorsResponseContent> {
        const response = await this.listDemonstratorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
