import {
  DefaultApi,
  Configuration,
  BadRequestErrorResponseContent,
  InternalFailureErrorResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
} from "@ods/api-typescript-react-query-hooks";
import { useContext, useMemo } from "react";
import { RuntimeConfigContext } from "../components/RuntimeContext";

export interface ApiError {
  readonly status: number;
  readonly details:
    | BadRequestErrorResponseContent
    | InternalFailureErrorResponseContent
    | NotFoundErrorResponseContent
    | NotAuthorizedErrorResponseContent;
}

export const isApiError = (e: unknown): e is ApiError =>
  !!(e && typeof e === "object" && "status" in e && "details" in e);

export const errorTitle = (status: number): string => {
  switch (status) {
    case 400:
      return "Bad Request";
    case 403:
      return "Not Authorized";
    case 404:
      return "Not Found";
    case 500:
      return "Server Error";
    default:
      return "Error";
  }
};

export const useDefaultApiClient = () => {
  const runtimeContext = useContext(RuntimeConfigContext);

  return useMemo(() => {
    return runtimeContext?.apiUrl
      ? new DefaultApi(
          new Configuration({
            basePath: runtimeContext.apiUrl,
            fetchApi: window.fetch,
          }),
        )
      : undefined;
  }, [runtimeContext?.apiUrl]);
};
