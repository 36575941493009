import { ColumnLayout, Spinner } from "@cloudscape-design/components";
import { useGetDemonstrator } from "@ods/api-typescript-react-query-hooks";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { StudioBackCard } from "../components/back-card";
import { ErrorAlert } from "../components/error-alert";
import { StudioCard } from "../components/studio-card";

export interface DemonstratorProps {}

export const Demonstrator: FC<DemonstratorProps> = () => {
  const { demonstratorId } = useParams<{ demonstratorId: string }>();

  const demonstrator = useGetDemonstrator({ demonstratorId: demonstratorId! });

  if (demonstrator.isError) {
    return <ErrorAlert errors={[demonstrator.error]} />;
  }

  if (demonstrator.isLoading) {
    return <Spinner size="large" />;
  }

  return (
    <div style={{ margin: 100 }}>
      <ColumnLayout columns={4}>
        {demonstrator.data.presentations
          .filter((p) => !p.disablePublish)
          .map((p) => (
            <StudioCard
              title={p.useCase}
              imageUrl={p.coverImageUrl}
              imageAlt={p.useCase}
              onClick={() => {
                window.location.href = `${
                  p.presentationUrl
                }?demonstratorId=${demonstratorId!}`;
              }}
              industry={p.industry}
              description={p.description}
            />
          ))}
        <StudioBackCard />
      </ColumnLayout>
    </div>
  );
};
