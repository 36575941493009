/*! Copyright [Amazon.com](http://amazon.com/), Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0 */
import * as React from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import { Demonstrator } from "../../pages/demonstrator";
import { Demonstrators } from "../../pages/demonstrators";
import { TouchTable } from "../../pages/touchTable";

/**
 * Defines the Routes.
 */
const Routes: React.FC = () => {
  return (
    <ReactRoutes>
      <Route
        key="demonstrator"
        path="/:demonstratorId"
        element={<Demonstrator />}
      />
      <Route key="demonstrators" path="/" element={<Demonstrators />} />
      <Route key="touchtable" path="/touchtable" element={<TouchTable />} />
    </ReactRoutes>
  );
};

export default Routes;
