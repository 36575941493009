import { ColumnLayout } from "@cloudscape-design/components";
import { FC } from "react";
import { StudioCard } from "../components/studio-card";
//@ts-ignore

export interface TouchTableProps {}

export const TouchTable: FC<TouchTableProps> = () => {
  return (
    <>
      <div style={{ margin: 100 }}>
        <ColumnLayout columns={3}>
          <StudioCard
            title={"Barista"}
            imageUrl={`/images/Barista.jpg`}
            onClick={() =>
              (window.location.href = `https://dg7cmjfl0vxi2.cloudfront.net`)
            }
          />

          <StudioCard
            title={"Cloud Migration Factory"}
            imageUrl={`/images/cloudmigrations.jpg`}
            onClick={() =>
              (window.location.href = `https://d39sz8p8ml7cvl.cloudfront.net/`)
            }
          />

          <StudioCard
            title={"Data and Analytics"}
            imageUrl={`/images/dataandanalytics.jpg`}
            onClick={() =>
              (window.location.href = `https://ap-southeast-2.demo.ada.solutions-apj.aws.dev`)
            }
          />

          <StudioCard
            title={"SpaceTwin - Digital Twin"}
            imageUrl={`/images/SpaceTwin.jpg`}
            onClick={() =>
              (window.location.href = `https://d30x2l6rp0ppfh.cloudfront.net/explore`)
            }
          />

          <StudioCard
            title={"Environmental, Social and Governance"}
            imageUrl={`/images/esg-demo.jpg`}
            onClick={() =>
              (window.location.href = `https://dueqr477wcj8u.cloudfront.net`)
            }
          />

          <StudioCard
            title={"Generative AI Marketing Campaigns"}
            imageUrl={`/images/genai.jpg`}
            onClick={() =>
              (window.location.href = `https://dtkh9ks9uj5in.cloudfront.net`)
            }
          />

          <StudioCard
            title={"Question and Answer Bot"}
            imageUrl={`/images/qanda.jpg`}
            onClick={() =>
              (window.location.href = `https://487650604714.auth.ap-southeast-2.amazoncognito.com/login?redirect_uri=https%3A%2F%2Fsldwf2hnn2.execute-api.ap-southeast-2.amazonaws.com%2Fprod%2Fstatic%2Fclient.html&response_type=token&client_id=edonr1o5eb3o75p0tvbti33fa`)
            }
          />

          <StudioCard
            title={"Generative AI Application Builder"}
            imageUrl={`/images/GenAIPoweredApplications.jpg`}
            onClick={() =>
              (window.location.href = `https://d3bvazierni6wd.cloudfront.net/`)
            }
          />

          <StudioCard
            title={"Hologram Demonstrator"}
            imageUrl={`/images/holo.jpg`}
            onClick={() =>
              (window.location.href = `https://d2ermmizvbirzz.cloudfront.net/`)
            }
          />

          <StudioCard
            title={"AI Image Information"}
            imageUrl={`/images/AIImageInformation.jpg`}
            onClick={() =>
              (window.location.href = `https://di9lnxqz488b9.cloudfront.net/`)
            }
          />
        </ColumnLayout>
      </div>
    </>
  );
};
