import {
  SpaceBetween,
  Box,
  Header,
  Container,
} from "@cloudscape-design/components";
import React from "react";

export interface StudioCardProps {
  description?: string;
  industry?: string;
  imageUrl?: string;
  readonly imageAlt?: string;
  position?: "top" | "side";
  title: string;
  subtitle?: string;
  width?: string | number | undefined;
  onClick: () => void;
}

export const StudioCard: React.FC<StudioCardProps> = ({
  description = undefined,
  imageUrl,
  imageAlt,
  industry = undefined,
  position = "top",
  width = "33%",
  title,
  subtitle,
  onClick,
}) => {
  return (
    <div onClick={onClick} style={{ height: "100%" }}>
      <Container
        fitHeight
        footer={
          industry && (
            <SpaceBetween direction="horizontal" size="xxs">
              <Box fontWeight="bold" fontSize="body-s">
                Industry:{" "}
              </Box>
              <Box fontSize="body-s">{industry}</Box>
            </SpaceBetween>
          )
        }
        media={{
          content: (
            <img
              style={{ aspectRatio: "3 / 1" }}
              src={imageUrl}
              alt={imageAlt ?? "image"}
            />
          ),
          position,
          width,
        }}
      >
        <SpaceBetween direction="vertical" size="s">
          <SpaceBetween direction="vertical" size="xxs">
            <Box variant="h2">
              <Header variant="h1">{title}</Header>
            </Box>
            {subtitle && <Box variant="small">{subtitle}</Box>}
          </SpaceBetween>
          {description && <Box variant="p">{description}</Box>}
        </SpaceBetween>
      </Container>
    </div>
  );
};
