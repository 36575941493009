/* tslint:disable */
/* eslint-disable */
/**
 * OdsService
 * ODS Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Demonstrator } from './Demonstrator';
import {
    DemonstratorFromJSON,
    DemonstratorFromJSONTyped,
    DemonstratorToJSON,
} from './Demonstrator';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface ListDemonstratorsResponseContent
 */
export interface ListDemonstratorsResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof ListDemonstratorsResponseContent
     */
    nextToken?: string;
    /**
     * 
     * @type {Array<Demonstrator>}
     * @memberof ListDemonstratorsResponseContent
     */
    demonstrators: Array<Demonstrator>;
}


/**
 * Check if a given object implements the ListDemonstratorsResponseContent interface.
 */
export function instanceOfListDemonstratorsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "demonstrators" in value;

    return isInstance;
}

export function ListDemonstratorsResponseContentFromJSON(json: any): ListDemonstratorsResponseContent {
    return ListDemonstratorsResponseContentFromJSONTyped(json, false);
}

export function ListDemonstratorsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDemonstratorsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'demonstrators': ((json['demonstrators'] as Array<any>).map(DemonstratorFromJSON)),
    };
}

export function ListDemonstratorsResponseContentToJSON(value?: ListDemonstratorsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'demonstrators': ((value.demonstrators as Array<any>).map(DemonstratorToJSON)),
    };
}

