/* tslint:disable */
/* eslint-disable */
/**
 * OdsService
 * ODS Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Presentation } from './Presentation';
import {
    PresentationFromJSON,
    PresentationFromJSONTyped,
    PresentationToJSON,
} from './Presentation';

/**
 * Extends structures with details about when the resource was created/updated
 * @export
 * @interface GetDemonstratorResponseContent
 */
export interface GetDemonstratorResponseContent {
    /**
     * The time at which the resource was created
     * @type {number}
     * @memberof GetDemonstratorResponseContent
     */
    createdAt: number;
    /**
     * The time of the most recent update
     * @type {number}
     * @memberof GetDemonstratorResponseContent
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof GetDemonstratorResponseContent
     */
    demonstratorId: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemonstratorResponseContent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemonstratorResponseContent
     */
    documentationUrl: string;
    /**
     * 
     * @type {Array<Presentation>}
     * @memberof GetDemonstratorResponseContent
     */
    presentations: Array<Presentation>;
}


/**
 * Check if a given object implements the GetDemonstratorResponseContent interface.
 */
export function instanceOfGetDemonstratorResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "demonstratorId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "documentationUrl" in value;
    isInstance = isInstance && "presentations" in value;

    return isInstance;
}

export function GetDemonstratorResponseContentFromJSON(json: any): GetDemonstratorResponseContent {
    return GetDemonstratorResponseContentFromJSONTyped(json, false);
}

export function GetDemonstratorResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDemonstratorResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'demonstratorId': json['demonstratorId'],
        'name': json['name'],
        'documentationUrl': json['documentationUrl'],
        'presentations': ((json['presentations'] as Array<any>).map(PresentationFromJSON)),
    };
}

export function GetDemonstratorResponseContentToJSON(value?: GetDemonstratorResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'demonstratorId': value.demonstratorId,
        'name': value.name,
        'documentationUrl': value.documentationUrl,
        'presentations': ((value.presentations as Array<any>).map(PresentationToJSON)),
    };
}

