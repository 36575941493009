/* tslint:disable */
/* eslint-disable */
/**
 * OdsService
 * ODS Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Presentation
 */
export interface Presentation {
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    industryId: string;
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    industry: string;
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    useCaseId: string;
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    useCase: string;
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    presentationUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    coverImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Presentation
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof Presentation
     */
    disablePublish?: boolean;
}


/**
 * Check if a given object implements the Presentation interface.
 */
export function instanceOfPresentation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "industryId" in value;
    isInstance = isInstance && "industry" in value;
    isInstance = isInstance && "useCaseId" in value;
    isInstance = isInstance && "useCase" in value;
    isInstance = isInstance && "presentationUrl" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "coverImageUrl" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function PresentationFromJSON(json: any): Presentation {
    return PresentationFromJSONTyped(json, false);
}

export function PresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Presentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'industryId': json['industryId'],
        'industry': json['industry'],
        'useCaseId': json['useCaseId'],
        'useCase': json['useCase'],
        'presentationUrl': json['presentationUrl'],
        'version': json['version'],
        'coverImageUrl': json['coverImageUrl'],
        'description': json['description'],
        'disablePublish': !exists(json, 'disablePublish') ? undefined : json['disablePublish'],
    };
}

export function PresentationToJSON(value?: Presentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'industryId': value.industryId,
        'industry': value.industry,
        'useCaseId': value.useCaseId,
        'useCase': value.useCase,
        'presentationUrl': value.presentationUrl,
        'version': value.version,
        'coverImageUrl': value.coverImageUrl,
        'description': value.description,
        'disablePublish': value.disablePublish,
    };
}

